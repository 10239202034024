import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';
import { environment } from 'src/environments/environment';
import { catchError, of } from 'rxjs';
import { BaseService } from './base.service';
import { APIENDPOINT } from '../constant/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private baseService: BaseService,
    private toastController: ToastController
  ) { }

  login<T = void | any>(reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.AUTH.LOGIN, reqBody).pipe(catchError(this.errorHandler));
  }

  public logout(){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.AUTH.LOGOUT, null).pipe(catchError(this.errorHandler));
  }
  
  public forgotPassword(reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.AUTH.FORGOTPASSWORD, reqBody).pipe(catchError(this.errorHandler));
  }
  public resetPassword(reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.AUTH.RESETPASSWORD, reqBody).pipe(catchError(this.errorHandler));
  }

  me<T = void | any>(){
    return this.baseService.get<T>(environment.baseUrl+APIENDPOINT.AUTH.GETUSER);
  }
  /**
   * Handles errors by returning an observable of the error.
   * 
   * @param error - The error to handle.
   * @returns An Observable that emits the error.
   */
  errorHandler(error:any) {
    return of(error);
  }
}


