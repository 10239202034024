export const APIENDPOINT = {
    AUTH: {
        GETUSER:'auth/me',
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        FORGOTPASSWORD: 'auth/password-reset-email',
        RESETPASSWORD: 'auth/reset-password'
    },
    PROSPECT: {
        ACTIVE: 'prospect/active-prospects',
        CLOSED: 'prospect/closed-prospects',
        DETAIL: 'prospect/prospect-detail/', //prospectId
        ASSIGN: 'prospect/assign/', //prospectId/{user_id}
        CHANGESTATUS: 'prospect/change-status/', //prospect/change-status/{universityId}/{status}
        CHANGESTAGE: 'prospect/change-stage/', //prospect/change-stage/{universityId}/{stage}
        UPLOADDOCUMENT: (prospectId: number) => `prospect/${prospectId}/upload-document`, //prospect/{universityId}/upload-document
        DELETEDOCUMENT: 'prospect/delete-document/', // delete-document/{universityId}/{documentId}
        GETDOCUMENTS: (prospectId: number, offset: number) => `prospect/${prospectId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ADDNOTE: (prospectId: number) => `prospect/${prospectId}/add-note`, //prospect/{universityId}/add-note
        GETNOTES: (prospectId: number, offset: number) => `prospect/${prospectId}/get-notes/${offset}`, //prospect/{universityId}/add-note/{offset?},
        UPDATE: (prospectId: number) => `prospect/${prospectId}/update`,
        EDITNOTE: (universityId: number, noteId: number) => `prospect/${universityId}/edit-note/${noteId}`
    },
    CLIENT: {
        LIST: 'client/list', //list/{status}/{offset?}/{name?}
        DETAIL: 'client/client-detail/', //client/client-detail/{id}
        GETDOCUMENTS: (clientId: number, offset: number) => `client/${clientId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ASSIGN: 'prospect/assign/', //universityId/{user_id}
        CREATE: 'client/create',
        UPLOADDOCUMENT: (universityId: number) => `client/${universityId}/upload-document`,
        UPDATE: (universityId: number) => `client/${universityId}/update`,
        BILLINGDETAILS: 'client/billingdetails/', //{universityId}
        INVOICES: 'client/invoices/', //{universityID}/{offset}
        SAVEDISCOUNT : 'client/add-or-update-discount', // POST
        GETDISCOUNT : 'client/get-discount' // {universityID} GET
    },
    USERS: {
        GETSOCIELITEADMINS: 'users/socielite-admins', //users/socielite-admins/{offset}/{name}
        GETUNIADMINS: 'users/university-admins', //users/university-admins/{offset}/{name}",

        STUDENTS: {
            ALL: 'users/students/all/', //{universityId}
            IMPORT: 'users/students/import'
        },
        EDITCURRENTUSER:'user/update'
        
    },
    QUERY: {
        LIST: (universityID : number) => `query/university/queries/${universityID}`, //{universityId}/{offset}
        CHATS: 'query/query-chats/', //{universityId}/{queryId}/{offset},
        REPLY: (queryID : number) => `query/socielite-admin/reply-query/${queryID}`, //{query_id, content, university_id, sender_id}
        ALL_QUERIES : 'query/all-queries', // GET
        UPDATE_STATUS : (queryID : number) => `query/socielite-admin/update-status/${queryID}`, // PUT
        QUERY_DETAILS : 'query/details', // GET
        ADD_NOTE: 'query/add-note/', //{queryId}
        EDIT_NOTE: 'query/edit-note/', //{queryNoteId}
        GET_NOTES: 'query/notes/', //{queryId}
        DELETE_NOTE: 'delete-note/' //{queryNoteId}
    },
    COURSES: {
        ALL: 'courses/all/', // {universityId}
        IMPORT: 'courses/import',
    },
    COMMUNICATON: {
        COMMUNICATION: 'communication/get/',
        LIST: 'communication/all-communication/',
        SEND: 'communication/send',
        EDIT: 'communication/edit/',
        PROFILE: 'communication/profile',
        EDITPROFILE: 'communication/profile/edit',
        EDITMEDIA: 'communication/profile/media/edit',
        SOCIALMEDIALINKDELETE: 'communication/delete/social-media-link/' //{$id}
    },
    FAQ: {
        FAQ: 'faq/get/',
        LIST: 'faq/all-faq/',
        POST: 'faq/create',
        EDIT: 'faq/edit/',
        DELETE: 'faq/delete/'
    },
    TUTORIAL:{
        TUTORIAL:'tutorial/get/',
        LIST:'tutorial/all-tutorials',
        POST: 'tutorial/create',
        EDIT:'tutorial/edit/',
        DELETE: 'tutorial/delete/', //{tutorialId}
        HIDE: 'tutorial/make-hidden/', //{tutorialId}
        UNHIDE: 'tutorial/unhide/', //{tutorialId}
        FILTER: 'tutorial/filter'
    },
    TEAMMEMBER:{
        TEAMMEMBER:'team-member/get/',
        LIST:'team-member/all/',//{teamId}
        POST:'team-member/get/',
        EDIT:'team-member/edit/', //{userId}
        ADD: 'team-member/add'
    },
    TEAM: {
        CREATE: 'team/create',
        ALL: 'team/list/',
        ROLESPERMISSIONS: 'team/roles-permissions/',
        UPDATEROLES: 'team/update-roles/',
        GET: 'team/get/',
    },
    STATISTIC:{
        GETACTIVEUSERS:'statistics/chart-data/',
        GETCARDDATA:'statistics/cards-data'
    },
    PERMISSIONS: {
        ALL: 'permissions/all' //{permission}
    },

    // LOCALIZATION - BACKOFFICE
    LANGUAGE: {
        LOAD: 'language/load/', // {language}
        LOADALL: 'language/get',
        GETLIST: 'language/list', // {language?}
        NEW: 'language/create', // POST {name, code}
        SAVE: 'language/save/', // {language} POST {translations}
        PUBLISH: 'language/publish/', // {language} PUT
        UNPUBLISH: 'language/unpublish/', // {language} DELETE
    },
    EXCELTEMPLATE: {
        UPLOAD: 'excel-template/upload',
        GET: 'excel-template/' //{type}
    },
    ADMIN_DASHBOARD: {
        INVOICES_TRACKER: 'admin-dashboard/invoice-tracker',
        ADMINS: 'admin-dashboard/list-admin',
        RENEWAL_OVERVIEW: 'admin-dashboard/renewal-overview',
        STATISTICS: {
            TILES: 'admin-dashboard/statistics-tiles',
            USERS: 'admin-dashboard/statistics-users',
            SUBSCRIPTIONS: 'admin-dashboard/statistics-subscriptions',
        }
    },
    INVOICE: {
        ALL: 'invoice/all-draft',
        ALL_CONFIRMED: 'invoice/all-confirmed',
        ALL_PAID: 'invoice/all-paid',
        ITEMS: 'invoice/items/all', //{frequency}
        CREATE: 'invoice/create-invoice',
        CREATE_MANUAL: 'invoice/create-manual-invoice',
        PREVIEW : 'invoice/preview', // {invoiceID}
        SEND_INVOICE : 'invoice/send-invoice', // {invoiceID}
        DOWNLOAD_INVOICE : 'invoice/generatePdf', // {invoiceID}
        LIST_CURRENCY : 'invoice/currencies', // GET
        CUSTOMER_CURRENCY : 'invoice/customer-currency', // {userID}
        CURRENCY_SYMBOL : 'invoice/customer-symbol', // {code}
        SEND_EMAIL_INVOICE : 'invoice/customer/send-email-invoice', // {invoiceID} - stripe id
        RESET_TO_DRAFT : 'invoice/revision', //{invoiceID}'
        SAVE_FINALIZE : 'invoice/save-finalize-invoice', //{invoiceID}'
        UPDATE_DRAFT_INVOICE : 'invoice/update-draft-invoice', //{invoiceID}'
        DELETE_DRAFT_INVOICE : 'invoice/delete-draft-invoice', //{invoiceID}'
    },

    SETTINGS: {
        SOCIALMEDIA: 'settings/social-media',
        CATEGORIES: 'settings/categories',
        CURRENCY : 'settings/currency'
    },

    CREDIT_NOTE : {
        ISSUE_CREDIT_NOTE_INVOICE : 'credit-note/add-invoice-credit-note', // {invoiceID} // POST
        LIST_INVOICE_CREDIT_NOTE : 'credit-note/list-invoice-credit-note', // {invoiceID} // GET
        VIEW_CREDIT_NOTE : 'credit-note/view-credit-note', // {ID} // GET
        DISCOUNT_DETAILS : 'credit-note/discount', // {couponID} // GET
    },

    PAYMENT : {
        ALL : 'invoice-payment/all-payment',
        PAYMENT_DETAIL : 'invoice-payment/details', // GET
        CATEGORIES: 'settings/categories',
        // PAYMENT_DETAIL : 'invoice-payment/details' // GET
    },
 
    HANDBOOK : {
        CREATE : 'handbook/create-handbook', // POST
        CATEGORIES : 'handbook/get-categories', // GET
        LIST : 'handbook/list-handbook', // GET
    },

    ENROLMENT_FEE: {
        UPDATE: 'enrolment-fee/update/', //enrolmentFeeId
        GET: 'enrolment-fee/get' //enrolmentFeeId
    },
    PLAN: {
        CREATESTANDARD: 'plan/standard/create',
        CREATECUSTOM: 'plan/custom/create',
        EDITSTANDARD: 'plan/standard/edit/', //{planId}
        EDITCUSTOM: 'plan/custom/edit/', //{planId}
        VIEW: 'plan/view/', //{planId}
        DELETE: 'plan/delete/', //{planId}
        STANDARD: 'plan/standard',
        CUSTOM: 'plan/custom',
        UNIVERSITYSUBSCRIPTION: 'plan/university/subscription/'//{universityId}
    },
    NOTIFICATIONSETTINGS: {
        GET: 'notification-settings/get',
        UPDATE: 'notification-settings/update',
    },
    STUDENT: {
        BAN: 'university/student/ban/', //{universityId}/{userId}
        DELETE: 'university/student/delete/',//{universityId}/{userId}'
        CREATE: 'university/student/new-user',
        EDIT: 'university/student/edit-student/' //{studentID}
    },
    BATCH: {
        CREATE: 'university/batch/create/', //{universityId}
        GET: 'university/batch/get/', //{universityId}/{courseId}
        UPDATE: 'university/batch/update/', //{universityId}/{courseId}
        DELETE: 'university/batch/delete/' //{universityId}/{courseId}
    },
    UNIPROFILE: {
        GET: 'university/profile/', //{universityId}
        EDITMEDIA: 'university/profile/media/edit/', //{universityId}
        EDITINFO: 'university/profile/edit/', //{universityId}
        DELETESOCIAL: 'university/profile/delete/social-media-link/', // {universityId}/{mediaLinkId}
    }

}