import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { User } from 'src/app/models/user.interface';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userSubject = new BehaviorSubject<User | null>(null);

  constructor(
    private storageService: StorageService
  ) {
    this.loadUser();
  }

  get user() {
    return this.userSubject.asObservable();
  }

  async loadUser() {
    const currentUser = await this.storageService.get('user');
    if (currentUser) {
      this.userSubject.next(currentUser);
    }
  }

  setUser(user: User) {
    this.storageService.set('user', user);
    this.userSubject.next(user);
  }

  getUser<User>() {
    return this.userSubject.value
  }
}
