import { Routes } from '@angular/router';
import { authRoutes } from './pages/auth/auth.route';
import { pageRestrictGuard } from './helpers/guard/page-restrict.guard';

export const routes: Routes = [

  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    children: authRoutes
  },
  {
    path: 'socielite-admin',
    canActivateChild: [pageRestrictGuard],
    loadComponent: () =>
      import('./pages/socielite-admin/socielite-admin.page').then(
        (m) => m.SocieliteAdminPage
      ),
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadComponent: () => import('./pages/socielite-admin/home/home.component').then(m => m.HomeComponent) },
      { path: 'prospects', loadComponent: () => import('./pages/socielite-admin/prospects/prospects.component').then(m => m.ProspectsComponent) },
      { path: 'clients', loadComponent: () => import('./pages/socielite-admin/clients/clients.component').then(m => m.ClientsComponent) },
      { path: 'prospect', loadComponent: () => import('./pages/socielite-admin/prospect-detail/prospect-detail.component').then(m => m.ProspectDetailComponent) },
      { path: 'client', loadComponent: () => import('./pages/socielite-admin/client-detail/client-detail.component').then(m => m.ClientDetailComponent) },
      { path: 'invoices', loadComponent: () => import('./pages/socielite-admin/invoices/invoices.component').then(m => m.InvoicesComponent) },
      { path: 'communications', loadComponent: () => import('./pages/socielite-admin/communications/communications.component').then(m => m.CommunicationsComponent) },
      {
        path: 'team',
        loadComponent: () => import('./pages/socielite-admin/team/team.component').then(m => m.TeamComponent),
        pathMatch: 'full'
      },
      {
        path: 'team/add',
        loadComponent: () => import('./pages/socielite-admin/team/add-team/add-team.component').then(m => m.AddTeamComponent),
        pathMatch: 'full'
      },
      {
        path: 'team/:id/manage-roles',
        loadComponent: () => import('./pages/socielite-admin/team/team-role/team-role.component').then(m => m.TeamRoleComponent),
        pathMatch: 'full'
      },
      { path: 'support', loadComponent: () => import('./pages/socielite-admin/support/support.component').then(m => m.SupportComponent) },
      { path: 'support/query', loadComponent: () => import('./pages/socielite-admin/support/query/query.component').then(m => m.QueryComponent) },
      { path: 'statistic', loadComponent: () => import('./pages/socielite-admin/statistic/statistic.component').then(m => m.StatisticComponent) },
      { path: 'settings', loadComponent: () => import('./pages/socielite-admin/settings/settings.component').then(m => m.SettingsComponent) },
      { path: 'team-role/:id', loadComponent: () => import('./pages/socielite-admin/team/team-role/team-role.component').then(m => m.TeamRoleComponent) },
      { path: 'team-manage/:id', loadComponent: () => import('./pages/socielite-admin/team/role-manage/role-manage.component').then(m => m.RoleManageComponent) },
      { path: 'invoices/create-invoice', loadComponent: () => import('./pages/socielite-admin/component/create-invoice/create-invoice.component').then(m => m.CreateInvoiceComponent) },
      { path: 'invoices/edit-invoice', loadComponent: () => import('./pages/socielite-admin/component/edit-invoice/edit-invoice.component').then(m => m.EditInvoiceComponent) },
      { path: 'translation', loadComponent: () => import('./pages/socielite-admin/translation/translation.component').then(m => m.TranslationComponent) },
      { path: 'invoices/create-invoice', loadComponent: () => import('./pages/socielite-admin/component/create-invoice/create-invoice.component').then(m => m.CreateInvoiceComponent) },
      { path: 'invoices/edit-invoice', loadComponent: () => import('./pages/socielite-admin/component/edit-invoice/edit-invoice.component').then(m => m.EditInvoiceComponent) },
      { path: 'invoices/create-credit-note', loadComponent: () => import('./pages/socielite-admin/component/create-credit-note/create-credit-note.component').then(m => m.CreateCreditNoteComponent) },
      { path: 'invoices/view-credit-note/:id', loadComponent: () => import('./pages/socielite-admin/component/view-credit-note/view-credit-note.component').then(m => m.ViewCreditNoteComponent) },
      { path: 'invoices/list-credit-note', loadComponent: () => import('./pages/socielite-admin/component/list-credit-note/list-credit-note.component').then(m => m.ListCreditNoteComponent) },
    ]
  },
  {
    path: 'university-admin',
    loadComponent: () => import('./pages/university/university.page').then( m => m.UniversityPage),
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadComponent: () => import('./pages/university/dashboard/dashboard.component').then(m => m.DashboardComponent) },
      { path: 'profile', loadComponent: () => import('./pages/university/profile/profile.component').then(m => m.ProfileComponent) },
      { path: 'students', loadComponent: () => import('./pages/university/students/students.component').then(m => m.StudentsComponent) },
      { path: 'union', loadComponent: () => import('./pages/university/students-union/students-union.component').then(m => m.StudentsUnionComponent) },
      { path: 'calendar', loadComponent: () => import('./pages/university/calendar/calendar.component').then(m => m.CalendarComponent) },
      { path: 'support', loadComponent: () => import('./pages/university/support/support.component').then(m => m.SupportComponent) },
      { path: 'shops', loadComponent: () => import('./pages/university/shops/shops.component').then(m => m.ShopsComponent) },
      { path: 'settings', loadComponent: () => import('./pages/university/settings/settings.component').then(m => m.SettingsComponent) },
    ]
  },

];
